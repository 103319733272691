<template>
	<v-main class="pa-0" :key="componentKey">
		<v-card elevation="1">
			<v-card-title
				class="text-subtitle-1 font-weight-bold"
				:class="$vuetify.theme.dark ? 'secondary darken-1' : 'grey lighten-3 grey--text text--darken-1'"
			>
				<v-icon
					small
					left
					:class="$vuetify.theme.dark ? 'secondary darken-1' : ' grey--text text--darken-1'"
					v-text="'fas fa-tasks'"
				></v-icon>
				Kara Liste
				<v-spacer></v-spacer>
				<page-form @save-data="add" :form-data.sync="formData"></page-form>
			</v-card-title>

			<v-card-text class="pa-0">
				<v-data-table
					:headers="headers"
					:options.sync="options"
					:items="blackListData"
					:server-items-length="blackListDataTotalCount"
					class="elevation-0"
				>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
									<v-icon x-small> fa-trash </v-icon>
								</v-btn>
							</template>
							<span>Listeden Kaldır</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-main>
</template>

<script>
import EventBus from '@/event-bus';
import { mapActions, mapGetters } from 'vuex';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { BLACK_LIST_GRID_DATA, BLACK_LIST_REMOVE_LISTED, BLACK_LIST_ADD } from '@/store/modules/company.module';

import PageForm from './Form.vue';

export default {
	name: 'blackListEdit',
	components: {
		PageForm,
	},
	data() {
		return {
			componentKey: 0,
			exporting: false,
			supervisorDialog: false,
			selectedItem: null,
			options: {
				itemsPerPage: 10,
				page: 1,
				sortBy: [],
				sortDesc: [true],
				mustSort: true,
			},
			headers: [
				{ text: 'Telefon Numarası', value: 'phoneNumber', align: 'left' },
				{ text: 'İşlemler', value: 'actions', sortable: false, align: 'center' },
			],
			formData: {
				phoneNumber: null,
			},
		};
	},
	computed: {
		...mapGetters(['blackListData', 'blackListDataTotalCount']),
	},
	created() {
		this.$nextTick(() => {
			EventBus.$on('refreshRequestGrid', () => {
				this.componentKey += 1;
			});
		});
	},
	beforeDestroy() {
		EventBus.$off('refreshRequestGrid');
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchGridData: BLACK_LIST_GRID_DATA,
			removeList: BLACK_LIST_REMOVE_LISTED,
			addBlackList: BLACK_LIST_ADD,
		}),
		deleteItem(item) {
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: 'Listeden kaldırmak istediğinize emin misiniz?',
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () =>
							this.removeList(item).then(() => {
								this.disposeMessageDialog();
							}),
					},
				],
			});
		},
		add() {
			const payload = { phoneNumber: this.formData.phoneNumber };
			this.addBlackList(payload).then(() => {
				this.setMessageDialog({
					messageType: 'info',
					subTitle: 'Başarılı',
					text: 'Telefon numarası kara listeye eklenmiştir.',
				});

				const newPayload = {
					...payload,
					pageSize: this.options.itemsPerPage,
					pageNumber: 1,
				};
				this.fetchGridData(newPayload);
				this.formData.phoneNumber = null;
			});
		},
	},
	watch: {
		options: {
			handler(value) {
				const { sortBy, sortDesc } = value;
				const sortField = sortBy[0] ?? 0;
				const fieldIndex = this.headers.findIndex((x) => x.value === sortField);
				const orderByFieldId = fieldIndex < 0 ? 0 : fieldIndex + 1;
				const orderByDesc = sortDesc[0] ?? true;

				const payload = { ...this.$refs.filterRef?.filter };

				const newPayload = {
					...payload,
					pageSize: value.itemsPerPage,
					pageNumber: value.page,
					orderByFieldId,
					orderByDesc,
				};
				this.fetchGridData(newPayload);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.theme--light .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.03);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.03);
						}
					}
				}
			}
		}
	}

	.theme--dark .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.3);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.3);
						}
					}
				}
			}
		}
	}
}
.hide-chip {
	visibility: hidden;
}
</style>
