<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark v-bind="attrs" v-on="on"> Yeni Ekle </v-btn>
			</template>
			<v-card>
				<v-card-title>
					<span class="text-h5">Kara Listeye Ekle</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field-simplemask
									v-model="formData.phoneNumber"
									v-bind:label="'Cep Telefon Numarası'"
									v-bind:properties="{
										color: 'teal',
										inputmode: 'numeric',
										prefix: '+90',
										placeholder: '(555)-555-55-55',
										dense: true,
										outlined: true,
										hideDetails: 'auto',
										flat: true,
									}"
									v-bind:options="{
										inputMask: '(###)-###-##-##',
										outputMask: '##########',
										empty: null,
										applyAfter: false,
										alphanumeric: false,
										lowerCase: false,
									}"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close"> Kapat </v-btn>
					<v-btn
						color="blue darken-1"
						text
						:dark="!$v.formData.$invalid"
						@click="save"
						:disabled="$v.formData.$invalid"
					>
						Kaydet
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import blacklistValidationMixin from './blacklistValidationMixin';

export default {
	name: 'PageForm',
	mixins: [validationMixin, blacklistValidationMixin],
	props: {
		formData: {
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
		};
	},
	methods: {
		save() {
			this.$emit('save-data');
			this.close();
		},
		close() {
			this.dialog = false;
		},
	},
};
</script>

<style></style>
