/* eslint-disable no-unused-expressions */
import { required, helpers } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const blacklistValidationMixin = {
	validations: {
		formData: {
			phoneNumber: {
				required,
				phoneCheck,
			},
		},
	},
	methods: {
		phoneNumberErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.formData.phoneNumber;
			if (!$dirty) return errors;
			!required && errors.push('Telefon numarası gereklidir.');
			return errors;
		},
	},
};

export default blacklistValidationMixin;
